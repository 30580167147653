<template>
	<section class="taskManagementPage">
		<GBreadcrumb bread="小程序用户" />
		<SearchForm>
			<el-form ref="ref_searchForm" :inline="true" :model="searchForm" label-width="80px" @submit.native.prevent>
				<el-form-item label="" prop="realName">
					<el-input v-model="searchForm.realName" placeholder="请输入用户昵称" />
				</el-form-item>
				<el-form-item label="" prop="userName">
					<el-input v-model="searchForm.userName" placeholder="请输入用户姓名" />
				</el-form-item>
				<el-form-item label="" prop="mobilePhone">
					<el-input v-model="searchForm.mobilePhone" placeholder="请输入用户手机号码" />
				</el-form-item>
				<el-form-item label="" prop="parentId">
					<el-select v-model="searchForm.parentId" placeholder="请选择所属客户" @change="changeDoctor" filterable
						clearable>
						<el-option v-for="item in options" :key="item.id" :label="item.realName" :value="item.id" />
						<div style="bottom: -10px">
							<GPagination v-show="total5 > 0" ref="ref_searchForm" :total="total5" :page.sync="searchForm2.page"
								:size.sync="searchForm2.size" @pagination="fetchData5" />
							</el-pagination>
						</div>

					</el-select>
				</el-form-item>
				<el-form-item label="" prop="merchantId">
					<el-select v-model="searchForm.merchantId" placeholder="请选择所属供应商" @change="changeDoctor" filterable
						clearable>
						<el-option v-for="item in options2" :key="item.merchantId" :label="item.merchantName"
							:value="item.merchantId" />
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="fetchData">搜 索</el-button>
				</el-form-item>
			</el-form>
		</SearchForm>

		<TableContainer :title="currentTextBook.teachingName">
			<template slot="topContent">
				<ul class="tabHeader">
					<li v-for="(item, index) in tabsOptions" :key="index"
						:class="parseInt(activeTabsName) === item.id?'active':''" @click="tabClick(item.id)">
						<div class="img-box">
							<img :src="tabsOptionsUrl[index]">
							<p>{{ item.teachingName }}</p>
						</div>
						<SvgIcon icon-class="smile" size="34px" class="smile" />
					</li>
				</ul>
			</template>
			<template slot="button">
				<el-button type="success" @click="addTheme" class="turnBule">添加用户</el-button>
			</template>
			<el-table :data="tableData" row-key="treeId" :tree-props="{children: 'children'}" border
				:header-cell-style="tabHeader" :cell-style="{ textAlign: 'center' }">
				<!-- <el-table-column prop="themeName" label="用户" min-width="100" class-name="text_left">
          <template slot-scope="scope">
            <span v-if="scope.row.treeNode === 1">{{ scope.row.themeName }}</span>
            <span v-if="scope.row.treeNode !== 1">{{ scope.row.curriculumName }}</span>
          </template>
        </el-table-column> -->
				<el-table-column prop="realName" label="用户昵称" min-width="160" />
				<el-table-column prop="userName" label="用户姓名" min-width="160" />
				<el-table-column prop="sexName" label="用户性别" min-width="100" />
				<el-table-column prop="buyStatus" label="购买状态" min-width="100" />
				<el-table-column prop="address" label="地址" min-width="100" />
				<el-table-column prop="mobilePhone" label="手机号码" min-width="100" />
				<el-table-column prop="merchantName" label="商户号" min-width="100" />
				<el-table-column prop="regName" label="注册时间" min-width="100" />
				<el-table-column label="操作" width="330">
					<template slot-scope="scope">
						<el-button type="text" class="reviewed" @click="viewUsers(scope.row)">用户订单表</el-button>
						<el-button type="text" class="reviewed" v-if="scope.row.flag === 1"
							@click="editTheme(scope.row)">编辑用户</el-button>
						<!-- <el-button v-if="scope.row.treeNode === 1" type="text" class="reviewed"
							@click="settingSubjecClick(scope.row)">设置用户资料</el-button> -->
						<!-- <el-button v-if="scope.row.treeNode === 1" type="text" class="reviewed" @click="addCourseClick(scope.row)">添加课程</el-button> -->
						<el-button type="text" class="textOrange" v-if="scope.row.flag === 1"
							@click="deleteTheme(scope.row)">删除用户</el-button>

						<!-- <el-button v-if="scope.row.treeNode !== 1" type="text" class="reviewed" @click="editCourseClick(scope.row)">编辑课程</el-button> -->
						<!-- <el-button v-if="scope.row.treeNode !== 1" type="text" class="textOrange" @click="deleteCourse(scope.row)">删除课程</el-button> -->
					</template>
					<!-- <template slot-scope="scope">
						<el-button v-if="scope.row.treeNode === 1" type="text" class="reviewed"
							@click="editTheme(scope.row)">编辑用户</el-button>
						<el-button v-if="scope.row.treeNode === 1" type="text" class="reviewed"
							@click="settingSubjecClick(scope.row)">设置用户资料</el-button>
						<el-button v-if="scope.row.treeNode === 1" type="text" class="reviewed" @click="addCourseClick(scope.row)">添加课程</el-button>
						<el-button v-if="scope.row.treeNode === 1" type="text" class="textOrange"
							@click="deleteTheme(scope.row)">删除用户</el-button>
						<el-button v-if="scope.row.treeNode !== 1" type="text" class="reviewed" @click="editCourseClick(scope.row)">编辑课程</el-button>
						<el-button v-if="scope.row.treeNode !== 1" type="text" class="textOrange" @click="deleteCourse(scope.row)">删除课程</el-button>
					</template> -->
				</el-table-column>
			</el-table>
			<GPagination v-show="total > 0" ref="ref_GPagination" :total="total" :page.sync="searchForm.page"
				:size.sync="searchForm.size" @pagination="fetchData" />
		</TableContainer>

		<GDialog :dialog.sync="themeDialog" @btnSure="submitThemeForm('ref_themeForm')"
			@btnClose="resetThemeForm('ref_themeForm')">
			<el-form ref="ref_themeForm" :model="themeForm" :rules="rules" label-width="100px" @submit.native.prevent>
				<el-form-item label="用户姓名" prop="userName">
					<el-input v-model="themeForm.userName" maxlength="35" placeholder="请输入用户名称" />
				</el-form-item>

				<el-form-item label="用户性别" prop="sex">
					<el-select v-model="themeForm.sex" placeholder="请选择用户性别" @change="changeSex" clearable>
						<el-option v-for="item in options1" :key="item.ids" :label="item.name" :value="item.ids" />
					</el-select>
				</el-form-item>
				<el-form-item label="手机号" prop="mobilePhone">
					<el-input v-model="themeForm.mobilePhone" maxlength="35" placeholder="请输入用户手机号" />
				</el-form-item>
				<el-form-item label="用户地址" prop="address">
					<el-input v-model="themeForm.address" maxlength="35" placeholder="请输入用户地址" />
				</el-form-item>
				<el-form-item label="所属客户" prop="parentId">
					<el-select v-model="themeForm.parentId" placeholder="请选择所属客户" @change="changeDoctor" filterable
						clearable>
						<el-option v-for="item in options" :key="item.id" :label="item.realName" :value="item.id" />
					</el-select>
				</el-form-item>
			</el-form>
		</GDialog>

		<GDialog :dialog.sync="themeDialog1" @btnSure="submitThemeForm1('ref_themeForm')"
			@btnClose="resetThemeForm('ref_themeForm')">
			<el-form ref="ref_themeForm" :model="themeForm" :rules="rules" label-width="100px" @submit.native.prevent>
				<el-form-item label="用户姓名" prop="userName">
					<el-input v-model="themeForm.userName" maxlength="35" placeholder="请输入用户名称" />
				</el-form-item>
				<el-form-item label="用户性别" prop="sex">
					<el-select v-model="themeForm.sex" placeholder="请选择用户性别" @change="changeSex" clearable>
						<el-option v-for="item in options1" :key="item.ids" :label="item.name" :value="item.ids" />
					</el-select>
				</el-form-item>
				<el-form-item label="手机号" prop="mobilePhone">
					<el-input v-model="themeForm.mobilePhone" maxlength="35" placeholder="请输入用户手机号" />
				</el-form-item>
				<el-form-item label="用户地址" prop="address">
					<el-input v-model="themeForm.address" maxlength="35" placeholder="请输入用户地址" />
				</el-form-item>
				<el-form-item label="所属客户" prop="parentId">
					<el-select v-model="themeForm.parentId" placeholder="请选择所属客户" @change="changeDoctor" filterable
						clearable>
						<el-option v-for="item in options" :key="item.id" :label="item.realName" :value="item.id" />
					</el-select>
				</el-form-item>
			</el-form>
		</GDialog>

		<GDialog :dialog.sync="gardenDialog1" @btnSure="submitThemeForm()" @btnClose="resetGardenForm()">
			<SearchForm>
				<el-date-picker v-model="daterange" :picker-options="pickerOptions" type="daterange" range-separator="至"
					start-placeholder="开始日期" end-placeholder="结束日期" @change="handleSearch" />
				<el-button type="primary" @click="fetchData2">搜 索</el-button>
			</SearchForm>
			<el-table :data="tableData2" stripe style="width: 100%">
				<el-table-column prop="realName" label="用户名" min-width="80" />
				<!-- <el-table-column prop="tradeNo" label="订单编号" min-width="100" /> -->
				<el-table-column prop="customerName" label="客户昵称" min-width="100" />
				<el-table-column prop="customerId" label="买家ID" min-width="100" />
				<el-table-column prop="customerNo" label="客户编码" min-width="100" />
				<el-table-column prop="receiverAddress" label="地址" min-width="100" />
				<el-table-column prop="receiverMobile" label="手机" min-width="100" />
				<el-table-column prop="paid" label="已付金额" min-width="100" />
				<el-table-column prop="created" label="订单日期" min-width="120" />
				<el-table-column width="120" label="操作">
					<template slot-scope="scope">
						<el-button type="text" class="reviewed" @click="editGarden2(scope.row)">商品详情</el-button>
					</template>
				</el-table-column>
			</el-table>
			<GPagination v-show="total11 > 0" ref="ref_GPagination" :total="total11" :page.sync="searchForm1.page"
				:size.sync="searchForm1.size" @pagination="fetchData1" />
		</GDialog>

		<GDialog :dialog.sync="gardenDialog2" @btnSure="submitThemeForm2()" @btnClose="resetGardenForm()">
			<el-table :data="tableData3" stripe style="width: 100%">
				<!-- <el-table-column prop="id" label="id" min-width="160" /> -->
				<el-table-column prop="goodsName" label="货品名称" min-width="100" />
				<el-table-column prop="num" label="下单数量" min-width="100" />
				<el-table-column prop="price" label="标价" min-width="100" />
				<el-table-column prop="refundStatus" label="退款状态" min-width="100" />
				<el-table-column prop="actualNum" label="实发数量" min-width="100" />
				<el-table-column prop="orderPrice" label="成交价" min-width="100" />
				<el-table-column prop="paid" label="已付" min-width="100" />

			</el-table>
			<!-- <GPagination v-show="total2 > 0" ref="ref_GPagination" :total="total2" :page.sync="searchForm.page"
				:size.sync="searchForm.size" @pagination="fetchData" /> -->
		</GDialog>

	</section>

</template>

<script>
	// import settingThemeData from './settingThemeData/settingThemeData.vue'
	// import addCourse from './addCourse/addCourse.vue'
	import {
		postFindMfDockor,
		findMfUserTradeByUserId,
		findTradeGoods
	} from '@api/gardenManagement/api_gardenManagement';
	import {
		findDockorOldUser,
		insertMfUser,
		updateMfUser,
		deleteMfUser,
		findMfMch,
	} from '@api/userMangement/api_userMangement'
	export default {
		// components: {
		// 	settingThemeData,
		// 	addCourse
		// },
		data() {
			return {
				activeTabsName: '',
				total: 0,
				total11: 0,
				total2: 0,
				total5: 0,
				currentThemeRow: {},
				tabsOptions: [],
				tabsOptionsUrl: [
					require('@/assets/images/L1A.jpg'),
					require('@/assets/images/L1B.jpg'),
					require('@/assets/images/L2A.jpg'),
					require('@/assets/images/L2B.jpg'),
					require('@/assets/images/L3A.jpg'),
					require('@/assets/images/L3B.jpg'),
					require('@/assets/images/L4A.jpg'),
					require('@/assets/images/L4B.jpg')
				],
				daterange: '',
				pickerOptions: {
					onPick({
						maxDate,
						minDate
					}) {
						// 当开始时间结束时间都选择了之后才执行搜索
						if (maxDate && minDate) {
							this.$nextTick(() => {
								that.handleSearch()
							})
						}
					}
				},
				beginTime: "",
				endTime: "",
				themeDialog: {
					title: '标题哦',
					visible: false
				},
				themeDialog1: {
					title: '标题哦',
					visible: false
				},
				gardenDialog1: {
					title: '标题哦',
					visible: false
				},
				gardenDialog2: {
					title: '标题哦',
					visible: false
				},
				searchForm: {
					realName: '',
					userName: '',
					mobilePhone: '',
					parentId: '',
					merchantId: '',
					page: 1,
					size: 10

				},
				searchForm1: {
					page: 1,
					size: 10
				},
				searchForm2: {
					page: 1,
					size: 10
				},
				options1: [{
						ids: 0,
						name: '男'
					},
					{
						ids: 1,
						name: '女'
					}
				],
				options2: [],

				tableData: [],
				tableData2: [],
				tableData3: [],
				options: [],
				themeForm: {
					userName: '',
					sex: '',
					mobilePhone: '',
					address: '',
					parentId: '',
				},
				rules: {
					userName: [{
						required: true,
						message: '请输入用户名称',
						trigger: 'blur'
					}],
					sex: [{
							required: true,
							message: '请输入用户性别',
							trigger: 'blur'
						},
						// { min: 3, max: 35, message: '请输入用户性别', trigger: 'blur' }
					],
					mobilePhone: [{
							required: true,
							message: '请输入用户手机号码',
							trigger: 'blur'
						},
						// { min: 3, max: 11, message: '请输入用户手机号码', trigger: 'blur' }
					],
					address: [{
							required: true,
							message: '请输入用户地址',
							trigger: 'blur'
						},
						// { min: 3, max: 35, message: '请输入用户地址', trigger: 'blur' }
					],
					parentId: [{
							required: true,
							message: '请输入用户地址',
							trigger: 'blur'
						},
						// { min: 1, max: 35, message: '请选择所属客户', trigger: 'blur' }
					],
				}
			}
		},
		computed: {
			currentTextBook() {
				let obj = {}
				for (const item of this.tabsOptions) {
					if (item.id === Number(this.activeTabsName)) {
						obj = item
						break
					}
				}
				return obj
			}
		},
	 created() {
			this.findDockorOldUser1();
			this.fetchData();
			this.findMfMch();
		},
		methods: {

			// tabClick(id) {
			// 	this.searchForm.themeName = ''
			// 	this.activeTabsName = id.toString()
			// 	this.fetchData()
			// },

			tabHeader() {
				return 'backgroundColor:rgba(88, 227, 232, 0.12);color:#000;font-weight:bold;font-size:15px;text-align: center;'
			},

			// getOptions() {
			// 	this.getMaterialList()
			// },
			findMfMch() {
				let params = {};
				findMfMch(params).then((res) => {
					this.options2 = res
				})
			},
			changeDoctor(val) {
				console.log(val)
				this.themeForm.parentId = val
			},
			changeSex(val) {
				this.themeForm.sex = val;
			},
			formatDate(date, fmt) {
				if (/(y+)/.test(fmt)) {
					fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
				}
				let o = {
					'M+': date.getMonth() + 1,
					'd+': date.getDate(),
					'h+': date.getHours(),
					'm+': date.getMinutes(),
					's+': date.getSeconds()
				}
				for (let k in o) {
					if (new RegExp(`(${k})`).test(fmt)) {
						let str = o[k] + ''
						fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : this.padLeftZero(str))
					}
				}
				return fmt
			},
			padLeftZero(str) {
				return ('00' + str).substr(str.length)
			},

			handleSearch(val) {
				// console.log(val,1111)
				// console.log(val[0],1111)
				let date = val[0];
				let date1 = val[1];
				this.beginTime = this.formatDate(date, 'yyyy-MM-dd hh:mm:ss');
				this.endTime = this.formatDate(date1, 'yyyy-MM-dd hh:mm:ss');
				// console.log(this.beginTime,1111)

			},
			
			fetchData5(){
				postFindMfDockor(this.searchForm2).then(res => {
					this.options = res.records
					this.total5 = res.total
				})
			},

			// 获取科目
			fetchData() {
				const params = {
					...this.searchForm,
					materiaId: Number(this.activeTabsName)
				}
				this.findDockorOldUser1()
			},

			fetchData2() {
				let params = {
					startTime: this.beginTime,
					endTime: this.endTime,
					page: 1,
					size: 10

				}
				findMfUserTradeByUserId(params).then(res => {
					this.tableData2 = res.records
					this.total11 = res.total
				})
			},

			// 用户列表
			findDockorOldUser1 () {
				let params = {
					...this.searchForm
				}
				findDockorOldUser(params).then(res => {
					this.tableData = res.records
					this.total = res.total
				})
				postFindMfDockor(params).then(res => {
					this.options = res.records
					this.total5 = res.total
				})

			},
			// 添加主题
			addTheme() {
				this.themeDialog.visible = true
				this.themeDialog.title = '添加用户'
				let params = {
					page: 1,
					size: 10
				}
				postFindMfDockor(params).then(res => {
					this.options = res.records
				})
			},
			editTheme(row) {
				console.log(row, 99999)
				this.themeDialog1.visible = true
				this.themeDialog1.title = '编辑用户'
				this.themeForm = row
			},
			editGarden2(row) {
				this.gardenDialog2.visible = true
				this.gardenDialog2.title = '商品详情'
				let params = {
					tradeId: row.tradeId,
					page: 1,
					size: 10

				}
				findTradeGoods(params).then(res => {
					console.log(res)
					this.tableData3 = res
					this.total2 = res.total
				})
			},
			viewUsers(row) {
				this.gardenDialog1.visible = true
				this.gardenDialog1.title = '用户订单'
				let params = {
					parentId: row.userId,
					...this.searchForm1

				}
				findMfUserTradeByUserId(params).then(res => {
					this.tableData2 = res.records
					this.total11 = res.total
				})
			},
			fetchData1() {
				findMfUserTradeByUserId(this.searchForm1).then(res => {
					this.tableData2 = res.records
					this.total11 = res.total
				})
			},
			deleteTheme(row) {
				this.$confirm('是否确认删除?', '提示', {
					type: 'warning',
					closeOnClickModal: false
				}).then(() => {
					deleteMfUser({
						userId: row.userId
					}).then(res => {
						this.$message.success('删除成功')
						this.fetchData()
					})
				})
			},
			resetThemeForm(formName) {
				this.$refs[formName].resetFields()
				this.themeForm.themeName = ''
				this.themeDialog.visible = false
				this.themeDialog1.visible = false
			},

			// 提交主题
			submitThemeForm(formName) {
				console.log(2222)
				this.$refs[formName].validate(valid => {
					console.log(valid, 1111)
					if (!valid) {

						return false
					}
					const params = {
						...this.themeForm
					}
					insertMfUser(params).then(res => {
						this.$message.success(this.themeDialog.title + '成功')
						this.resetThemeForm('ref_themeForm')
						this.fetchData()
						this.$refs.ref_addCourse.getOptions()
					})

				})
			},
			submitThemeForm1(formName) {
				console.log(formName, 2222)
				this.$refs[formName].validate(valid => {
					console.log(valid, 1111)
					if (!valid) {

						return false
					}
					const params = {
						...this.themeForm
					}
					// let _api
					// params.materialId = Number(this.activeTabsName)
					// if (this.themeDialog.title === '添加主题') {
					// 	_api = addTheme
					// } else {
					// 	if (this.currentThemeRow.themeName === params.themeName) {
					// 		this.$message.error('主题名称未作修改！')
					// 		return false
					// 	}
					// 	_api = updateTheme
					// 	params.themeId = this.currentThemeRow.id
					// }
					updateMfUser(params).then(res => {
						this.$message.success(this.themeDialog1.title + '成功')
						this.resetThemeForm('ref_themeForm')
						this.fetchData()
						this.$refs.ref_addCourse.getOptions()
					})
				})
			},
			submitThemeForm2() {
				this.gardenDialog2.visible = false
			},
			// submitThemeForm() {
			// 	this.gardenDialog1.visible = false
			// },
			resetGardenForm(formName) {
				// this.$refs[formName].resetFields()
				this.gardenDialog1.visible = false
			}
			// 设置 主题资料 点击
			// settingSubjecClick(row) {
			// 	this.currentThemeRow = row
			// 	this.$nextTick(() => {
			// 		this.$refs.ref_settingSubjec.isShowsettingSubject = true
			// 		this.$refs.ref_settingSubjec.fetchData()
			// 	})
			// },
			// 添加课程 点击
			// addCourseClick(row) {
			// 	this.currentThemeRow = row
			// 	this.$store.commit('courseStep/SET_addOrEdit', 'add')
			// 	this.$nextTick(() => {
			// 		this.$refs.ref_addCourse.isShowAddCourse = true
			// 		this.$refs.ref_addCourse.addCourseForm.themeIds = [row.id]
			// 	})
			// },
			// 编辑课程 点击
			// editCourseClick(row) {
			// 	this.currentThemeRow = row
			// 	this.$store.commit('courseStep/SET_addOrEdit', 'edit')
			// 	this.$nextTick(() => {
			// 		this.$refs.ref_addCourse.isShowAddCourse = true
			// 		this.$refs.ref_addCourse.getCourseDetail()
			// 	})
			// },
			// deleteCourse(row) {
			// 	this.$confirm('是否确认删除?', '提示', {
			// 		type: 'warning',
			// 		closeOnClickModal: false
			// 	}).then(() => {
			// 		deleteCurriculum({
			// 			curriculumId: row.id
			// 		}).then(res => {
			// 			this.$message.success('删除成功')
			// 			this.fetchData()
			// 		})
			// 	})
			// },

			// 重置排序 向上
			// sortUp(row) {
			// 	let func = ''
			// 	if (row.treeNode === 1) {
			// 		func = moveUpTheme(row.id)
			// 	} else {
			// 		func = moveUpCurriculum(row.teachingThemeId, row.id)
			// 	}
			// 	func.then(res => {
			// 		this.fetchData()
			// 	})
			// },
			// 重置排序 向下
			// sortDown(row) {
			// 	let func = ''
			// 	if (row.treeNode === 1) {
			// 		func = moveDownTheme(row.id)
			// 	} else {
			// 		func = moveDownCurriculum(row.teachingThemeId, row.id)
			// 	}
			// 	func.then(res => {
			// 		this.fetchData()
			// 	})
			// }
		}
	}
</script>

<style lang="scss">
	.turnBule {
		color: #fff;
		background-color: #58e3e8;
		border-color: rgba(56, 185, 190, 0.12);
	}

	.turnBule:hover,
	.turnBule:focus {
		background: rgba(56, 185, 190, 0.5);
		border-color: rgba(56, 185, 190, 0.5);
		color: #fff;
	}

	.el-button--text.reviewed {
		color: #38b9be;
	}

	.reviewed {
		color: #38b9be;
	}

	.taskManagementPage {
		.el-table .el-table__expand-icon .el-icon-arrow-right:before {
			content: "\e6d9";
			color: #8F8F8B;
		}

		.el-table__expand-icon--expanded {
			transform: rotate(0deg);
		}

		.el-table [class*=el-table__row--level] .el-table__expand-icon {
			border: 1px solid #EEEEEE;
			width: 20px;
			height: 20px;
			line-height: 19px;
			margin-right: 10px;
		}

		.el-table [class*=el-table__row--level] .el-table__expand-icon:hover {
			border-color: #ccc;
			cursor: pointer;
		}

		.el-table .el-table__expand-icon.el-table__expand-icon--expanded .el-icon-arrow-right:before {
			content: "\e6d8";
			color: #8F8F8B;
		}

		.sortIcon {
			padding: 6px;

			.el-icon-top,
			.el-icon-bottom {
				font-size: 14px;
				color: #53402B;
			}
		}

		.tabHeader {
			display: flex;
			padding-bottom: 6px;
			border-bottom: 1px solid #EEEEEE;
			margin-bottom: 10px;
			overflow-x: auto;

			li {
				text-align: center;
				margin-right: 24px;
				cursor: pointer;

				&:last-child {
					margin-right: 0;
				}

				.img-box {
					border: 3px solid white;
					border-radius: 12px;
					position: relative;

					img {
						width: 175px;
						height: 150px;
						border-radius: 12px;
						display: block;
						object-fit: cover;
					}

					p {
						position: absolute;
						left: 0;
						bottom: 0;
						right: 0;
						height: 32px;
						background: rgba(255, 255, 255, 0.8);
						color: #53402B;
						text-align: center;
						line-height: 32px;
						border-radius: 0 0 12px 12px;
						font-size: 14px;
					}
				}

				.smile {
					color: #58e3e8;
					opacity: 0;
					margin-top: -5px;
				}

				&.active {
					div {
						border-color: #58e3e8;
					}

					.smile {
						opacity: 1;
					}
				}
			}
		}

		//   .el-table__body .el-table__row td:first-child .cell{
		//     white-space: nowrap;
		//     overflow: hidden;
		//     text-overflow: ellipsis;
		//   }
		//   .el-table__body .el-table__row .el-button--success{
		//     box-shadow: none;
		//   }
	}
</style>
